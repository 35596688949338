import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import { SagaMiddleware } from "redux-saga";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./rootSaga";

import appReducer from "../app/appSlice";
import userReducer from "../users/userSlice";
import poolReducer from "../pools/poolSlice";
import loansReducer from "../loans/loanSlice";

const sagaMiddleware: SagaMiddleware = createSagaMiddleware();

const logger = createLogger({
  collapsed: true,
});

const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    pools: poolReducer,
    loans: loansReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(logger, sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export default store;

export interface StoreState {
  app: ReturnType<typeof appReducer>;
  user: ReturnType<typeof userReducer>;
  pools: ReturnType<typeof poolReducer>;
  loans: ReturnType<typeof loansReducer>;
}

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
