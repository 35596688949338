import React from 'react';
import ReactApexChart from 'react-apexcharts';
import Web3 from 'web3';
import { CarLoanCollection } from '../../services/cars/carTypes';
import { ApexOptions } from 'apexcharts';

interface ProcessedData {
  amortization: [number, number][];
  interest: [number, number][];
}

const ScatterPlot = ({ data }: { data: CarLoanCollection[] }) => {
  const web3 = new Web3();

  const processData = (data: CarLoanCollection[]): ProcessedData => {
    const aggregatedData: { [key: string]: { amortization: number; interest: number } } = {};

    data.forEach(item => {
      const date = new Date(item.createdAt);
      const dayKey = date.toISOString().split('T')[0]; // Use YYYY-MM-DD as the key
      const amortization = parseFloat(web3.utils.fromWei(item.amortization, 'ether'));
      const interest = parseFloat(web3.utils.fromWei(item.interest, 'ether'));

      if (!aggregatedData[dayKey]) {
        aggregatedData[dayKey] = { amortization: 0, interest: 0 };
      }

      aggregatedData[dayKey].amortization += amortization;
      aggregatedData[dayKey].interest += interest;
    });

    const processedData: ProcessedData = { amortization: [], interest: [] };

    Object.entries(aggregatedData).forEach(([dayKey, values]) => {
      const timestamp = new Date(dayKey).getTime();
      processedData.amortization.push([timestamp, parseFloat(values.amortization.toFixed(2))]);
      processedData.interest.push([timestamp, parseFloat(values.interest.toFixed(2))]);
    });

    return processedData;
  };

  const { amortization, interest } = processData(data);

  const options: ApexOptions = {
    chart: {
      height: 350,
      type: "scatter",
      zoom: {
        type: "xy"
      }
    },
    xaxis: {
      type: "datetime"
    },
    yaxis: {
      title: {
        text: "Amount CUSD"
      },
      decimalsInFloat: 2,
      labels: {
        formatter: (val: number) => val.toFixed(2)
      }
    },
    colors: ["#1E88E5", "#43A047"], // Blue for amortization, Green for interest
    legend: {
      labels: {
        colors: ["#1E88E5", "#43A047"]
      }
    },
    markers: {
      size: 6,
      strokeWidth: 0,
      hover: {
        size: 8
      }
    },
    tooltip: {
      y: {
        formatter: (val: number) => val.toFixed(2) + " CUSD"
      }
    }
  };

  const series = [
    {
      name: 'Amortization',
      data: amortization
    },
    {
      name: 'Interest',
      data: interest
    }
  ];

  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type="scatter"
        height={350}
      />
    </div>
  );
};

export default ScatterPlot;