const BlockData : React.FC<{ tip: string, data:boolean}> = ({tip, data}) => {

    return (
        <div className="flex flex-row mt-1">
        <div className="mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
            </svg>
        </div>
        <div className="flex flex-row">
          <div className="flex flex-col md:flex-row">{tip}</div>
          <div className="address-container bg-gray-100 text-gray-800 text-md font-medium mx-1 py-0.5  dark:bg-gray-500 dark:text-gray-900 px-2 px-2 ">
            {data? "true" : "false"}
          </div>
        </div>
      </div>
    )
}

export default BlockData