// src/App.tsx

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import AppRoutes from "./routes/app-routes";
import Modal from "./pages/modals/Modal";
import { QueryClient, QueryClientProvider } from 'react-query';

function App() {
  const queryClient = new QueryClient();

  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Modal />
          <AppRoutes />
        </Router>
      </QueryClientProvider>
    </AuthProvider>
  );
}

export default App;