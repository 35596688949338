import { CarLoanContract, CarLoanContractFull } from "../../services/cars/carTypes";
import {
    BlockAddress,
    BlockMoneyInLine,
    TXAddress,
    BlockCalendar,
    Progress,
  } from "../commons/BlockAddress";
  import { Link, useParams } from "react-router-dom";
import BlockMoney from "../commons/BlockMoney";
import BlockData from "../commons/BlockData";
import BlockRate from "../commons/BlockRate";
import BlockBoolean from "../commons/BlockBoolean";

const CarLoanBlockchain : React.FC<{carLoanContract: CarLoanContractFull}>=  ({carLoanContract}) => {
    return (
        <div className="px-4">
        <div className="p-4 lg:flex-auto">
          
        <BlockAddress
            type={"type"}
            address={carLoanContract.carloan}  
        />
        <BlockAddress
          type={"type"}
          address={carLoanContract.pool + " << pool"}  
        />
          <BlockAddress
            type={"type"}
            address={carLoanContract.borrower + " << borrower"}  
          />
          <BlockAddress
            type={"type"}
            address={carLoanContract.collector + " << collector"}  
          />
          <BlockMoney
            type={"type"}
            money={carLoanContract.cash}  
            tip={"Cash:"}
          />
          <BlockMoney
            type={"address"}
            money={carLoanContract?.principalRemaining}
            tip={"Principal Remaining:"}
          ></BlockMoney>
          
          <BlockMoney
            type={"address"}
            money={carLoanContract?.balanceRemaining}
            tip={"Remaining Balance:"}
          ></BlockMoney>

          <BlockMoney
            type={"interest"}
            money={carLoanContract?.interestPaid}
            tip={"Interest Paid:"}
          ></BlockMoney>

          <BlockMoney
            type={"address"}
            money={carLoanContract?.payment}
            tip={"Daily Payment:"}
          />

          <BlockCalendar
            date={carLoanContract?.initDate}
            tip={"Init date:"}
          />

          <BlockData
            data={carLoanContract?.paymentsMade}
            tip={"paymentsMade:"}
          />

          <BlockData
            data={carLoanContract?.payed}
            tip={"payed:"}
          />
          <BlockRate
            data={carLoanContract?.rateNumerator}
            tip={"daylirate:"}
          />
          <BlockRate
            data={carLoanContract?.rateNumerator}
            tip={"monthlyrate:"}
            base={30}
          />
          <BlockRate
            data={carLoanContract?.rateNumerator}
            tip={"yearlyrate:"}
            base={365}
          />
          {carLoanContract.status && 
          <BlockBoolean
            data={carLoanContract?.status}
            tip={"status:"}
          />}

          <div className="mt-5 ml-auto text-right">
            <Link
              to={"/pool/" + carLoanContract?.pool}
              className="ml-auto"
            >
              <button className="mt-10 block w-full bg-slate-300 px-3 py-2 text-center text-sm font-semibold text-black shadow-sm hover:bg-slate-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-300">
                Back to Pool
              </button>
            </Link>
          </div>
        </div>
      </div>
    )
}

export default CarLoanBlockchain