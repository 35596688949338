import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaymentFlow } from "../../pages/stake/payment/StakeIn";

export interface AppState {
  address: string;
  stakeIn?: {
    poolAddress: string;
    amount: string;
    method: string;
    paymentFlow: PaymentFlow;
  };
}

const initialState: AppState = {
  address: "0x0",
  stakeIn: undefined,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updateApp: (state, action: PayloadAction<Partial<AppState>>) => {
      return { ...state, ...action.payload };
    },
    clearApp: () => initialState,
  },
});

export const { updateApp, clearApp } = appSlice.actions;

export default appSlice.reducer;
