import React, { useState, useContext } from "react";

import Pool from "./Pool";
import { PoolDto } from "../../services/pools/poolTypes";

const PoolPanel: React.FC<{ pool: PoolDto }> = ({ pool }) => {
  return (
    <Pool
      pool={pool}
      poolContract={{}}
    />
  );
};

export default PoolPanel;
