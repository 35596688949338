import Web3 from "web3";
import { useEffect, useState } from "react";
import { CarDetail, CarLoan, CarLoanContract } from "../../services/cars/carTypes";
import { getCarLoansAvailable, getCarLoanContracts } from "../../services/cars/carsRest";
import { useQuery } from 'react-query';
export const carDefierAbi = require("../../contracts/CarDefier.sol/CarDefier.json");
export const currencyAbi = require("../../contracts/CERC20.sol/CERC20.json");
export const carLoanAbi = require("../../contracts/CarLoan.sol/CarLoan.json");

// Extend the Window interface to include ethereum and web3 properties
interface ExtendedWindow extends Window {
  ethereum?: any;
  web3?: {
    currentProvider: any;
  };
}
declare let window: ExtendedWindow;

const castFloat = (floatNumber: number) => {
  return BigInt(parseInt((floatNumber * 10000000000).toString())) * BigInt(100000000);
};

const ethLoanFormatting = (params: CarLoan) => {
  let dailyRate = Math.pow(parseFloat(params.rate.toString()) + 1, 1 / 365) - 1;
  let installments = params.days;
  let payment = castFloat(
    params.principal * (dailyRate / (1 - Math.pow(1 + dailyRate, -installments)))
  );
  dailyRate = parseInt((dailyRate * 1000000000).toString());
  let gracePeriod = params.gracePeriod ? params.gracePeriod : 1

  return {
    uuid: params.uuid.trim(),
    borrower: { address: "0xEBab6dd0ad56266b3A75B4FB65d9AD894525bcf2" },
    principal: castFloat(params.principal),
    payment: payment,
    dailyRate: dailyRate,
    installments: installments,
    gracePeriod,
  };
};

const Admin = () => {

  const [carLoanContracts, setCarLoanContracts] = useState<CarLoanContract[]>([]);
  const [web3Instance, setWeb3Instance] = useState<Web3 | null>(null);
  
  useEffect(() => {
    const loadWeb3 = async () => {
      if (window.ethereum) {
        try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const web3 = new Web3(window.ethereum);
          setWeb3Instance(web3);
          console.log('Web3 instance created');
        } catch (error) {
          console.error('User denied account access');
        }
      } else if (window.web3) {
        setWeb3Instance(new Web3(window.web3.currentProvider));
      } else {
        console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
      }
    };

    loadWeb3();
  }, []);


  const { data: carLoans , isLoading: loadingCarLoans, error: errorCarLoans } = useQuery(
    'getCarsAvailable',
    () => getCarLoansAvailable()
  );

  useEffect(() => {
    getCarLoanContracts()
      .then((res: any) => {
        setCarLoanContracts(res);
      })
      .catch((error: any) => {
        console.log(error.message);
      });
  }, []);

  const createLoan = async (carLoan: CarLoan, poolAddress: string) => {

    if (!poolAddress) {
      alert("No Pool Address")
      return;
    }

    if (!web3Instance) {
      alert("No web3 instance");
      return;
    }

    if (!window.ethereum) {
      alert("No web3 instance");
      return;
    }

    // Request account access
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const userAddress = accounts[0]; // Set the userAddress to the first account
    
    const carDefierContract = new web3Instance.eth.Contract(
      carDefierAbi.abi,
      process.env.REACT_APP_ADDRESS_CARDEFIER
    );

    const params = ethLoanFormatting(carLoan);

    console.log("params to deploy", params )
    
    try {
      await carDefierContract.methods
        .registerLoan(
          params.uuid,
          params.borrower.address,  
          params.principal,
          params.payment,
          params.dailyRate,
          params.installments,
          params.gracePeriod,
          poolAddress
        )
        .send({ from: userAddress });
      alert("Loan created successfully");
    } catch (error) {
      alert(error)
      console.error("Error creating loan:", error);
    }
  };

  const repayLoan = async (carLoanContract: CarLoanContract) => {
 
    if (!web3Instance) {
      alert("No web3 instance");
      return;
    }

    if (!window.ethereum) {
      alert("No web3 instance");
      return;
    }

    // Request account access
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const userAddress = accounts[0]; // Set the userAddress to the first account

    const currency = new web3Instance.eth.Contract(
      currencyAbi.abi,
      process.env.REACT_APP_ADDRESS_CURRENCY
    );

    await currency.methods.faucet(carLoanContract.carloan, carLoanContract.payment.trim()).send({from: userAddress}).then((response)=>{alert(response)})

    try {
      const balance = await currency.methods.balanceOf(userAddress).call();
      console.log("User balance:", balance, "Required for loan:", carLoanContract.payment.trim());

      const gasEstimate = await currency.methods
        .increaseAllowance(carLoanContract.carloan, carLoanContract.payment.trim())
        .estimateGas({ from: userAddress });

      await currency.methods
        .increaseAllowance(carLoanContract.carloan, carLoanContract.payment.trim())
        .send({ from: userAddress, gas: gasEstimate.toString() })
        .then((response) => {
          console.log("Allowance increased response:", response);
          // Implement the carLoan contract interaction here if needed
        })
        .catch((error) => {
          console.error("Error increasing allowance:", error);
        });
    } catch (error) {
      console.error("Error making payment:", error);
    }
  };

  return (
    <div className="grid grid-cols-2 gap-4 mx-auto w-full">
      <table className="table-auto">
        <thead>
          <tr>
            <td>UUID</td>
            <td>Principal</td>
            <td>Rate</td>
            <td>Actions</td>
          </tr>
        </thead>
        <tbody>
          {carLoans?.map((carLoans : CarLoan) => (
            <tr key={carLoans.uuid}>
              <td>{carLoans.uuid}</td>
              <td>{carLoans.principal}</td>
              <td>{carLoans.rate}</td>
              <td className="flex flex-row gap-2">
                <button
                  onClick={() => createLoan(carLoans, process.env.REACT_APP_ADDRESS_PGOLD as string)}
                  className="block rounded-md bg-indigo-600 px-1 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-indigo-600"
                >
                  Create on HIDDEN PGOLD
                </button>

                <button
                  onClick={() => createLoan(carLoans, process.env.REACT_APP_ADDRESS_PRISK as string)}
                  className="block rounded-md bg-indigo-600 px-1 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-indigo-600"
                >
                  Create on HIDDEN PRISK
                </button>

              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <hr />

      <table className="table-auto">
        <thead>
          <tr>
            <td>UUID</td>
            <td>carloan</td>
            <td>Principal</td>
            <td>Rate</td>
            <td>Actions</td>
          </tr>
        </thead>
        <tbody>
          {carLoanContracts?.map((car) => (
            <tr key={car.uuid}>
              <td>{car.carloan}</td>
              <td>{car.uuid}</td>
              <td>{car.principalRemaining}</td>
              <td>{car.payment}</td>
              <td className="flex flex-row gap-2">
                <button
                  onClick={() => repayLoan(car)}
                  className="block rounded-md bg-indigo-600 px-1 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-indigo-600"
                >
                  MAKE PAYMENT
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Admin;

