import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PoolDto } from "./poolTypes";

interface PoolsState {
  [poolAddress: string]: PoolDto;
}

const initialState: PoolsState = {};

export const poolSlice = createSlice({
  name: "pools",
  initialState,
  reducers: {
    addOrUpdatePool: (
      state,
      action: PayloadAction<{ poolAddress: string; pool: PoolDto }>
    ) => {
      const { poolAddress, pool } = action.payload;

      state[poolAddress] = pool;
    },
    removePool: (state, action: PayloadAction<string>) => {
      delete state[action.payload];
    },
    clearPools: () => initialState,
  },
});

export const { addOrUpdatePool, removePool, clearPools } = poolSlice.actions;

export default poolSlice.reducer;
