import { refreshToken } from '../services/auth/auth-service';

let refreshPromise: Promise<string | null> | null = null;

export const getValidToken = async (): Promise<string | null> => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  try {
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds

    if (Date.now() >= expirationTime) {
      if (!refreshPromise) {
        refreshPromise = refreshToken().then(newToken => {
          refreshPromise = null;
          if (newToken) {
            localStorage.setItem('token', newToken);
          } else {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
          }
          return newToken;
        }).catch(error => {
          console.error('Error refreshing token:', error);
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          refreshPromise = null;
          return null;
        });
      }
      return refreshPromise;
    }

    return token;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};