// src/components/UserWallets.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import { BlockAddress } from "../commons/BlockAddress";

type Wallet = {
  id: string;
  address: string;
  source: string;
};

function UserWallets({ userId }: { userId: string }) {
  const [wallets, setWallets] = useState<Wallet[]>([]);
  const [newWalletAddress, setNewWalletAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Fetch existing wallets
  useEffect(() => {
    const fetchWallets = async () => {
      try {
        setLoading(true);
        const response = await axios(`/api/users/wallets`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming JWT is stored in localStorage
          },
        });
        setWallets(response.data);
        setLoading(false);
      } catch (err) {
        // setError("Failed to fetch wallets");
        setLoading(false);
      }
    };
    fetchWallets();
  }, [userId]);

  // Add new wallet
  const addWallet = async () => {
    if (!newWalletAddress) {
      setError("Please enter a valid wallet address");
      return;
    }
    try {
      setLoading(true);
      const response = await axios(`/api/users/wallets`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming JWT is stored in localStorage
        },
        data: {
          address: newWalletAddress,
          source: "registered_on_site",
        },
      });
      setWallets([...wallets, response.data]);
      setNewWalletAddress("");
      setError("");
    } catch (err) {
      setError("Failed to add wallet");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="my-3">

      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}

      <p>We created a wallet to stake your funds on smart contracts. Soon you are going to be able to stake directly with your wallet. Check your wallet to get a proof of transactions made.</p>
      <ul>
        {wallets.map((wallet) => (
          <li key={wallet.id}>
            <BlockAddress type={"address"} address={wallet.address} />
            {/* {wallet.source === "registered_on_site"
              ? "Registered on site"
              : wallet.source} */}
          </li>
        ))}
      </ul>
      {/* <div>
        <button onClick={addWallet} className="flex flex-row align-center">
          <input
            type="text"
            value={newWalletAddress}
            onChange={(e) => setNewWalletAddress(e.target.value)}
            placeholder="Enter new wallet address"
            className="block flex-1 border-0 bg-slate-100 border-slate-200  py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 mr-2"
          />

          <span className="m-2 flex flex-auto">
            Add Wallet
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </span>
        </button>
      </div> */}
    </div>
  );
}

export default UserWallets;
