import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Loan } from "./loanTypes";

interface LoansState {
  [uuid: string]: Loan;
}

const initialState: LoansState = {};

export const loansSlice = createSlice({
  name: "loans",
  initialState,
  reducers: {
    addOrUpdateLoan: (
      state,
      action: PayloadAction<{ uuid: string; loan: Loan }>
    ) => {
      const { uuid, loan } = action.payload;
      state[uuid] = loan;
    },
    removeLoan: (state, action: PayloadAction<string>) => {
      delete state[action.payload];
    },
    clearLoans: () => initialState,
  },
});

export const { addOrUpdateLoan, removeLoan, clearLoans } = loansSlice.actions;

export default loansSlice.reducer;
