import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../auth/auth-types";

export interface UserState  {
  user: User | undefined;
  address: string;
  current_balance: number;
  total_staked: number;
  total_earned: number;
  gold_balance: number;
  risk_balance: number;
  gold_balance_staked: number;
  risk_balance_staked: number;
  gold_interest_earned: number;
  risk_interest_earned: number;
  gold_unstaked: number;
  risk_unstaked: number;
  pool_gold_gained: number;
  pool_risk_gained: number;
  ts: number;
}

const initialState: UserState = {
  user: undefined,
  address: "",
  current_balance: 0,  
  total_staked: 0,
  total_earned: 0,
  gold_balance: 0,
  risk_balance: 0,
  gold_balance_staked: 0,
  risk_balance_staked: 0,
  gold_interest_earned: 0,
  risk_interest_earned: 0,
  gold_unstaked: 0,
  risk_unstaked: 0,
  pool_gold_gained: 0,
  pool_risk_gained: 0,
  ts: 0,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<Partial<UserState>>) => {
      return { ...state, ...action.payload };
    },
    clearUser: () => initialState,
  },
});

export const { updateUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
