import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from 'react-query';
import SideBar from "../experience/SideBar";
import { CarLoanState, CarLoanEvent, CarLoanCapitalize, CarLoanContract, CarLoan, Car } from "../../services/cars/carTypes";
import Footer from "../commons/Footer";
import {
  BlockAddress,
  BlockMoneyInLine,
  TXAddress,
  BlockCalendar,
  Progress,
} from "../commons/BlockAddress";
import BlockData from "../commons/BlockData";
import { Link, useParams } from "react-router-dom";
import CarLoanBlockchain from "./CarLoanBlockchain";
import CarLoanDetails from "./CarLoanDetails";
import CarDetails from "./CarDetails";

import { getCarLoanContract, getCarLoanContractCollection, getCarLoanContractCapitalize } from "../../services/cars/carsRest";
import { useQuery } from 'react-query';
import Stake from "../stake/Stake";
import ScatterPlot from "./Scatter";

const CarLoanView = () => {

  
  const carLoanAddress = useParams().carLoanAddress;

  const { data, isLoading, error } = useQuery(
    'getCarLoanContract',
    () => getCarLoanContract(carLoanAddress!),
    {
      // Only execute the query if carLoanAddress is not undefined
      enabled: !!carLoanAddress,
    }
  );

  const { data: carLoanContractsEvents, isLoading: isLoadingEvents, error: errorEvents } = useQuery(
    'getCarLoanContractCollection',
    () => getCarLoanContractCollection(carLoanAddress!),
    {
      // Only execute the query if carLoanAddress is not undefined
      enabled: !!carLoanAddress,
    }
  );


  const { data: carLoanContractsCapitalize, isLoading: isLoadingCapitalize, error: errorCapitalize } = useQuery(
    'getCarLoanContractCapitalize',
    () => getCarLoanContractCapitalize(carLoanAddress!),
    {
      // Only execute the query if carLoanAddress is not undefined
      enabled: !!carLoanAddress,
    }
  );


  const [open, setOpen] = useState(false);

  const [carLoanEvents, setCarLoanEvents] = useState<CarLoanEvent[]>([]);

  if (isLoading) (<div>is loading...</div>)

  return (
    <div className="bg-white">
      <header className="absolute inset-x-0 top-0 z-50">
        <SideBar open={open} setOpen={setOpen} />
      </header>
      <div className="flex flex-col justify-center items-center mt-10">
        <div className="relative isolate px-6 pt-14 lg:px-8">
          {data?.carLoanContract.pool &&
            <Stake
              pool={"PGOLD"}
              invested={1000}
              poolColor={"yellow"}
              poolAddress={data.carLoanContract.pool}
              rendering={{ stake: undefined }}
            />
          }
          <div className="mx-auto max-w-2xl py-4">
            <div
              className={`mx-auto max-w-2xl rounded-xl md:rounded-2xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none flex flex-col bg-white`}
            >
              {data !== undefined &&
                <>
                  <CarDetails car={data.carLoanContract.car} />

                  <CarLoanBlockchain carLoanContract={data.carLoanContract} />

                  <hr/>

                  <CarLoanDetails carLoan={data.carLoanContract.carLoan} />

                  <hr/>

                </>
              }
            </div>

          </div>
          <div className="mx-auto max-w-2xl py-4">
            <div className="mx-auto mt-5 max-w-2xl rounded-xl md:rounded-2xl sm:mt-20 lg:mx-0 lg:flex lg:max-w-none flex flex-col">
              <div className="p-4 lg:flex-auto">
                <h3 className="text-xl font-bold tracking-tight text-gray-900 text-center mb-5">
                  Payments history
                </h3>

                {carLoanContractsEvents &&
                  <ScatterPlot data={carLoanContractsEvents} />
                }
                <div className="p-4 md:p-8 flex flex-col">
                  {carLoanContractsEvents !== undefined && carLoanContractsEvents.map((event: CarLoanEvent) => (
                    <div key={event.hash} className="flex flex-col">
                      <div>
                        <BlockCalendar tip={""} date={event.createdAt}></BlockCalendar>
                        <BlockData tip={""} data={event.number}></BlockData>
                        <TXAddress type={"tx"} tx={event.hash}></TXAddress>
                        <BlockMoneyInLine type={"wei"} money={event.amortization} tip={" Principal"} />
                        <BlockMoneyInLine type={"wei"} money={event.interest} tip={" Interest"} />
                      </div>
                      <div className="mt-5">
                        <hr></hr>
                      </div>
                    </div>
                  ))}
                </div>
            </div>
          </div>  
          <div className="mx-auto max-w-2xl py-4">
            <div className="mx-auto mt-5 max-w-2xl rounded-xl md:rounded-2xl sm:mt-20 lg:mx-0 lg:flex lg:max-w-none flex flex-col">
                
                <h3 className="text-xl font-bold tracking-tight text-gray-900 text-center mb-5">
                  Collections history
                </h3>

                <div className="p-4 md:p-8 flex flex-col">
                  {carLoanContractsCapitalize !== undefined && carLoanContractsCapitalize.map((event: CarLoanCapitalize) => (
                    <div key={event.hash} className="flex flex-col">
                      <div>
                        <BlockCalendar tip={""} date={event.createdAt}></BlockCalendar>
                        <BlockData tip={""} data={event.number}></BlockData>
                        <TXAddress type={"tx"} tx={event.hash}></TXAddress>
                        <BlockData data={event.periods} tip={" Periods"} />
                        <BlockMoneyInLine type={"wei"} money={event.balanceRemaining} tip={" Principal"} />
                        <BlockMoneyInLine type={"wei"} money={event.interest} tip={" Interest"} />
                      </div>
                      <div className="mt-5">
                        <hr></hr>
                      </div>
                    </div>
                  ))}
                </div>


              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );

   
};

export default CarLoanView;
