import React, { useState, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateApp } from "../../services/app/appSlice";
import { PaymentFlow } from "./payment/StakeIn";

const Stake: React.FC<{
  pool: string;
  invested: number | undefined;
  poolColor: string;
  poolAddress: string;
  rendering: { stake?: string };
}> = ({ pool, invested, poolColor, poolAddress, rendering }) => {
  const [valueToStake, setValueToStake] = useState(0);
  const dispatch = useDispatch();

  const placeStake = (event: any) => {
    //let v = Math.min(10000,event.target.value)
    let v = event.target.value;
    setValueToStake(v);
  };

  const currencySymbol = "$";

  const loadWeb3 = () => {
    // rendering.loadWeb3;
  };

  const handleButtonClick = () => {
    dispatch(
      updateApp({
        stakeIn: {
          poolAddress,
          amount: "100",
          method: "method1",
          paymentFlow: PaymentFlow.makePayment,
        },
      })
    );
  };

  return (
    <div className="w-full">
      <div
        className={`mt-2 p-2 md:h-full lg:mt-0 lg:w-full  lg:flex-shrink-0 ${poolColor}`}
      >
        <div className="rounded-2xl md:h-full bg-gray-50 py-6 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center">
          <div className="mx-auto px-8">
            {!rendering.stake ? (
              <div>
                <h3 className="text-base font-semibold text-gray-600">
                  Invest now and get <br></br> the best RISK-APR
                </h3>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-1"></div>

                  {/*<div className="absolute inset-y-0 right-0 flex items-center pr-3"> {currencySymbol} </div>*/}
                </div>

                <button
                  className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  // onClick={() => {
                  //   stakeIn(poolAddress, valueToStake);
                  // }}
                  onClick={handleButtonClick}
                >
                  Stake now!
                </button>

                {(invested && invested > 0)?
                  <p className="mt-6 text-xs leading-5 text-gray-600">
                    (your balance on this pool: {invested})
                  </p> :
                  <p>
                    Make your first invesment
                  </p>
                }
              
              </div>
            ) : rendering.stake == "allowing" ? (
              <div className="text-center">
                <h3>Allowance in Process (step 1 / 2)</h3>
                <p>
                  You need to allow Car Defier to access your {valueToStake}{" "}
                  {currencySymbol} on Metamask wallet.
                </p>
                <p>
                  This confirmation should be validated by the blockchain and
                  take some seconds.
                </p>
                <hr></hr>
                <img
                  src="https://cardefier.io/img/cdn/metamask-logo.svg"
                  className="w-10"
                  style={{ margin: "2px auto" }}
                  onClick={loadWeb3}
                  alt="step 2 of 2"
                />
              </div>
            ) : (
              <div className="text-center">
                <h3>Staking (step 2 / 2)</h3>
                <p>
                  Please confirm on MetaMask that you are going to stake{" "}
                  {valueToStake} {currencySymbol} on this contract.
                </p>
                <p>
                  After confirmation blockchain might take some seconds to
                  settle the operation.
                </p>
                <hr></hr>
                <img
                  src="https://cardefier.io/img/cdn/metamask-logo.svg"
                  className="w-10"
                  style={{ margin: "2px auto" }}
                  onClick={loadWeb3}
                  alt={"step 2 of 2"}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stake;
