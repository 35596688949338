import React from 'react';
import ReactApexChart from 'react-apexcharts';
import Web3 from "web3";


interface GradientDonutChartProps {
  stake: string;
  gains: string;
  pool: string;
}

const GradientDonutChart: React.FC<GradientDonutChartProps> = ({ stake, gains, pool }) => {
  const web3 = new Web3();

  const stakeValue = parseFloat(web3.utils.fromWei(stake, "ether"));
  const gainsValue = parseFloat(web3.utils.fromWei(gains, "ether"))
  const totalValue = stakeValue + gainsValue;

  const series = [stakeValue, gainsValue];
  const labels = ['Stake', 'Gains'];

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'donut',
    },
    labels,
    legend: {
      position: 'bottom',
      formatter: function (seriesName, opts) {
        return `${seriesName}: ${opts.w.globals.series[opts.seriesIndex]}`;
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: 'Total',
              formatter: () => totalValue.toString(),
            },
          },
        },
      },
    },
    fill: {
      type: 'gradient',
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${(val as number).toFixed(2)}%`,
    },
  };

  return (
    <div>
      <ReactApexChart options={options} series={series} type="donut" height={350} />
      <div style={{ textAlign: 'center', marginTop: '10px' }}>
        <strong>Your stake on {pool} pool: {totalValue}</strong>
      </div>
    </div>
  );
};

export default GradientDonutChart;
