import React, { useEffect, useState } from "react";
import axios from "axios";
import { BlockAddress, TXAddress } from "../commons/BlockAddress";

function UserPaymentIntents() {
  const [paymentIntents, setPaymentIntents] = useState([]);
  const [visibleIntents, setVisibleIntents] = useState(5); // Initial visible intents
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchPaymentIntents = async () => {
      setLoading(true);
      try {
        const response = await axios.get("/api/users/payments", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Ensure you're using correct auth headers
          },
        });
        setPaymentIntents(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch payment intents");
        setLoading(false);
      }
    };

    fetchPaymentIntents();
  }, []);

  const showMore = () => {
    setVisibleIntents(paymentIntents.length); // Show all records
  };

  const showLess = () => {
    setVisibleIntents(5); // Reset to initial state
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
  };

  return (
    <div className="flex flex-col justify-start border-solid border-2 border-indigo-600 p-5 rounded-xl w-full mt-10">
      <h2 className="mb-5">
        <b>Your Stakes</b>
      </h2>
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      <div>
        {paymentIntents.slice(0, visibleIntents).map((intent: any) => (
           <div className="flex flex-col mb-3 border-t-4 py-2">
              <div className="flex flex-row mb-1">
              <div className=" mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                  />
                </svg>
              </div>              

              {formatDate(intent.updatedAt)}

              {intent.status === "Pending" ? (
                <span className="ml-1 inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                  Pending
                </span>
              ) : (
                <span className="ml-1 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                  Confirmed
                </span>
              )}
              
              <div className="ml-auto">${intent.amount / 100}</div>
            </div>
              {(intent.txhash && intent.txhash.length) > 0 && (
                <TXAddress type="tx" tx={intent.txhash} />
              )}
          </div>
        ))}
      </div>
      {visibleIntents < paymentIntents.length && (
        <button
          onClick={showMore}
          className="flex ml-auto justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-10"
        >
          Show More
        </button>
      )}
      {visibleIntents > 5 && (
        <button
          onClick={showLess}
          className="flex ml-auto justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Show Less
        </button>
      )}
    </div>
  );
}

export default UserPaymentIntents;
