const BlockRate : React.FC<{ tip: string, data: number, base?: number}> = ({tip, data, base  = 1}) => {

    return (
        <div className="flex flex-row mt-1">
        <div className="mr-2">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m9 14.25 6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0c1.1.128 1.907 1.077 1.907 2.185ZM9.75 9h.008v.008H9.75V9Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm4.125 4.5h.008v.008h-.008V13.5Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
          </svg>
        </div>
        <div className="flex flex-row">
          <div className="flex flex-col md:flex-row">{tip}</div>
          <div className="address-container bg-gray-100 text-gray-800 text-md font-medium mx-1 py-0.5  dark:bg-gray-500 dark:text-gray-900 px-2 px-2 ">
            { ((Math.pow((data*1) / 1000000000 + 1 , base) - 1)*100).toFixed(6)}
          </div>
        </div>
      </div>
    )
}

export default BlockRate