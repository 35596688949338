import React, { useContext } from "react";
import UserPanel from "./UserPanel";
import smalllogo from "../../assets/smalllogo.svg";
import burger from "../../assets/burger.svg";
import { Link, useNavigate } from "react-router-dom";

declare global {
  interface Window {
    ethereum?: any;
  }
}

const SideBar: React.FC<{
  open: boolean;
  setOpen: (open: boolean) => void;
}> = ({ open, setOpen }) => {
  //const { openMetamask, userAddress } = useContext(Web3ProviderContext);
  const userAddress = "0x0";
  const openMetamask = () => {
    alert("should open metamask");
  };

  const navigate = useNavigate();

  const profile = () => {
    navigate("/user_profile");
  };

  return (
    <div className="bg-white py-3 fixed top-0 left-0 right-0 shadow-md">
      <div className="block grid grid-cols-2 content-between">
        {/* <button className="ml-4" onClick={() => setOpen(true)}> */}

        <button className="ml-4" onClick={() => profile()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
        </button>
        <a href="../../">
          <img
            src={smalllogo}
            className="h-8 mr-4"
            style={{
              marginLeft: "auto",
            }}
            alt="display menú"
          />
        </a>
      </div>

      <div
        className={`${
          !open && "hidden"
        } bg-gray-600/50 min-h-screen w-full fixed top-0 left-0 right-0 backdrop-blur-sm`}
        onClick={() => setOpen(false)}
      ></div>

      <div
        className={`${
          open ? "w-300" : "w-0"
        } bg-zinc-50 min-h-screen fixed top-0 left-0 transition-all duration-300`}
      >
        <div
          className={`${!open && "hidden"} pt-3`}
          style={{ minWidth: "300px", maxWidth: "80%" }}
        >
          <button
            className="ml-4 text-black mb-14"
            onClick={() => setOpen(false)}
          >
            <img src={burger} alt="access menú" />
          </button>

          {["0x0", null, undefined].includes(userAddress) ? (
            <div>
              <button
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 L-Affiliate-Tagged hidden"
                onClick={() => {
                  openMetamask();
                }}
                style={{ padding: "10px", margin: "10px auto" }}
              >
                Connect your wallet
              </button>

              <Link to="/user_profile">
                <button
                  className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 L-Affiliate-Tagged"
                  style={{ padding: "10px", margin: "10px auto" }}
                >
                  Your Profile
                </button>
              </Link>

              <div className="flex justify-center gap-5">
                <Link to="/login">Login</Link>
                <Link to="/register">Register</Link>
              </div>

              {!window?.ethereum ? (
                <div className="text-center">
                  <h3>Wallet not detected</h3>

                  <div style={{ maxWidth: "300px" }}>
                    For a complete experience on CarDefier, consider installing
                    or enabling a Web3 wallet.{" "}
                    <a
                      href="https://metamask.io/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline"
                    >
                      MetaMask
                    </a>{" "}
                    is the one we use to have the best performance on our site.
                  </div>
                </div>
              ) : (
                <div className="text-center">
                  {/* <h3>You are not connected</h3>

                  <div style={{ maxWidth: "300px" }}>
                    Your wallet is not connected to CarDefier. Contract
                    interaction (like getting your balance, staking, or
                    unstaking) requires your wallet to be enabled.
                  </div> */}
                </div>
              )}
            </div>
          ) : (
            <div>
              <UserPanel />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SideBar;
