import React from "react";

const B2BPreview = () => {
  return (
    <div className="pb-80 pt-16 sm:pb-40 sm:pt-24 lg:pb-48 lg:pt-40">
      <div className="relative mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
        <div className="sm:max-w-lg">
          <h1 className="font text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            Don't miss your chance to sell! Get funding for your cars.
          </h1>
          <p className="mt-4 text-xl text-gray-500">
            If you are a Car dealer that wants to obtain funding for its cars,
            just click below.
          </p>
          <a
            className="inline-block rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-center font-medium text-white hover:bg-indigo-700"
            href="https://wa.me/+5491139199196"
          >
            Sell your Car Loan
          </a>
        </div>
        <div>
          <div className="mt-10">
            {/* Decorative image grid */}
            <div
              aria-hidden="true"
              className="pointer-events-none lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl"
            >
              <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                <div className="flex items-center space-x-2 lg:space-x-6">
                  <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                    <div className="h-22 w-36 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100">
                      <img
                        src="/carimages/car01.jpg"
                        alt=""
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                    <div className="h-22 w-36 overflow-hidden rounded-lg">
                      <img
                        src="/carimages/car02.jpg"
                        alt=""
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                  </div>
                  <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                    <div className="h-22 w-36 overflow-hidden rounded-lg">
                      <img
                        src="/carimages/car03.jpg"
                        alt=""
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                    <div className="h-22 w-36 overflow-hidden rounded-lg">
                      <img
                        src="/carimages/car04.jpg"
                        alt=""
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                    <div className="h-22 w-36 overflow-hidden rounded-lg">
                      <img
                        src="/carimages/car05.jpg"
                        alt=""
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                  </div>
                  <div className="hidden md:grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                    <div className="h-22 w-36 overflow-hidden rounded-lg">
                      <img
                        src="/carimages/car06.jpg"
                        alt=""
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                    <div className="h-22 w-36 overflow-hidden rounded-lg">
                      <img
                        src="/carimages/car07.jpg"
                        alt=""
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default B2BPreview;
