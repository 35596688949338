import { EventsPoolStakeDto } from "../../services/pools/poolTypes";
import {
  BlockAddress,
  BlockCalendar,
  BlockMoneyInLine,
  TXAddress,
} from "../commons/BlockAddress";

const PoolStakeEvents = ({
  stakingEvents,
}: {
  stakingEvents: EventsPoolStakeDto[];
}) => {
  return (
    <div className="mx-auto max-w-2xl py-4 md:p8 min-h-screen">
      <div className="mx-auto mt-5 md:mt-14 max-w-2xl rounded-xl md:rounded-2xl sm:mt-20 lg:mx-0 lg:flex lg:max-w-none flex flex-col">
        <div className="p-4 md:p-8  lg:flex-auto">
          <h3 className="text-xl font-bold tracking-tight text-gray-900 text-center mb-5">
            Staking Activity
          </h3>

          <div className="p-4 md:p-8 flex flex-col">
            {stakingEvents.map((event) => {
              return (
                <div
                  key={event.transaction_hash}
                  className="flex flex-col mb-5"
                >
                  <BlockCalendar tip={""} date={event.created_at} />
                  <BlockAddress type={"address"} address={event.staker} />
                  <TXAddress type={"tx"} tx={event.transaction_hash} />
                  <BlockMoneyInLine
                    type={"wei"}
                    money={event.side ? event.amount : "-" + event.amount}
                    tip={""}
                  />
                  <hr></hr>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoolStakeEvents;
