import axios from "axios";
import { User } from "./auth-types";

export const userProfileRest = async (): Promise<User> => {
  const token = localStorage.getItem('token');  
  const response = await axios.get<User>("/api/users/profile", {
    headers: { Authorization: `Bearer ${token}` }
  });
  return response.data;
};

export const refreshToken = async (): Promise<string | null> => {
  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) {
    console.error('No refresh token available');
    return null;
  }
  
  try {
    const response = await axios.post<{ access_token: string }>("/api/auth/refresh", { refreshToken });
    const newToken = response.data.access_token;
    return newToken;
  } catch (error) {
    console.error('Error refreshing token:', error);
    return null;
  }
};