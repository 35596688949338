import { CarLoan } from "../../services/cars/carTypes";

import BlockData from "../commons/BlockData";
import BlockBoolean from "../commons/BlockBoolean";

const CarLoanDetails : React.FC<{carLoan: CarLoan}>= ({carLoan}) => {

    return (<>
                <div className="px-4">
                    <div className="p-4 lg:flex-auto">

                      <h2>Original conditions</h2>

                      <BlockData
                        data={carLoan?.uuid}
                        tip={"UUID:"}
                      />

                      <BlockData
                        data={carLoan?.principal}
                        tip={"principal:"}
                      />
                      <BlockData
                        data={carLoan?.remainingBalance}
                        tip={"remainingBalance:"}
                      />
                      <BlockData
                        data={carLoan?.payment}
                        tip={"payment:"}
                      />
                      <BlockData
                        data={carLoan?.gracePeriod}
                        tip={"gracePeriod:"}
                      />
                      <BlockData
                        data={carLoan?.rate}
                        tip={"rate:"}
                      />
                      <BlockData
                        data={carLoan?.days}
                        tip={"days:"}
                      />
                      <BlockBoolean
                        data={carLoan?.status}
                        tip={"status:"}
                      />

                    </div>
                
                </div>


          {/* <BlockCalendar
            tip={"Maturity (days): "}
            date={carLoanDetail.carLoan?.days}
          ></BlockCalendar>

          <Progress
            tip={"Maturity (days): "}
            date={carLoanDetail.carLoan?.days}
          ></Progress> */}
    </>)
}

export default CarLoanDetails