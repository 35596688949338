import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EventTable from './eventTable';


const DataShow = () => {
  const [cardefierEvents, setCardefierEvents] = useState([]);
  const [poolsEvents, setPoolsEvents] = useState([]);
  const [carLoansEvents, setCarLoansEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      const cardefierResponse = await axios.get('/api/events/cardefier');
      setCardefierEvents(cardefierResponse.data);

      const poolsResponse = await axios.get('/api/events/pools');
      setPoolsEvents(poolsResponse.data);

      const carLoansResponse = await axios.get('/api/events/carloans');
      setCarLoansEvents(carLoansResponse.data);
    };

    fetchEvents();
  }, []);

  return (
    <div>
      <h1>Event Dashboard</h1>
      <EventTable title="Cardefier Events" events={cardefierEvents} />
      <EventTable title="Pools Events" events={poolsEvents} />
      <EventTable title="Car Loans Events" events={carLoansEvents} />
    </div>
  );
};

export default DataShow;
