import axios from "axios";
import { EventsPoolStakeDto, PoolDto } from "./poolTypes";

export const getPoolStakeEvents = async (
  poolAddress: string
): Promise<EventsPoolStakeDto[]> => {
  //const url = process.env.REACT_APP_API_SERVER + "/api/collection/event/" + poolAddress;
  // const url = "/api/events-pool-stake/" + poolAddress;
  const url = "/api/events/events-pool-stake";

  return axios
    .get(url, {
      params: {
        poolAddress,
      },
    })
    .then((res) => {
      // You should return the actual data here, not an empty array
      return res.data;
    })
    .catch((error) => {
      console.log(error.message);
      // You should handle the error here, not just return an empty array
      return [];
    });
};


export const getPool = async (carloan : string): Promise<PoolDto> => {
  const url = "/api/pools/" + carloan + "/details";
  const response = await axios.get<PoolDto>(url)
  return response.data
};

export const getPools = async (): Promise<PoolDto[]> => {
  //const url = process.env.REACT_APP_API_SERVER + "/api/collection/event/" + poolAddress;
  // const url = "/api/events-pool-stake/" + poolAddress;
  const url = "/api/pools";

  return axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error.message);

      return [];
    });
};

export const getUserPoolState = async (carloan: string, userAddress: string): Promise<{stake:string; gains:string;}> => {
  const url = "/api/pools/" + carloan + "/user-state/" + userAddress;
  const response = await axios.get<{stake:string; gains:string;}>(url)
  return response.data
};