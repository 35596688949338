import { call, fork, put } from "redux-saga/effects";
import { addOrUpdatePool } from "../pools/poolSlice";
import { getPools } from "../pools/poolRest";
import { SagaIterator } from "redux-saga";

function* startingApp(): SagaIterator {
  console.log("Application was started");
  const pools = yield call(getPools);

  for (const pool of pools) {
    yield put(
      addOrUpdatePool({
        poolAddress: pool.pool,
        pool: pool,
      })
    );
  }

  // POPULATE REDUCERS
  // INSTANTIATE SOCKETS
}

export default function* rootSaga() {
  console.log("rootSaga was started");
  yield fork(startingApp);
}
