import { useState } from "react";
import CreatePayment from "./creditCard/CreatePayment";
import PaymentSuccess from "./PaymentSuccess";
import PaymentHold from "./PaymentHold";
import PaymentError from "./PaymentError";
import { useSelector } from "react-redux";
import { RootState } from "../../../services/app/store";
import StripePricingTable from "../../stripecheckout/PricingTable";

export enum PaymentFlow {
  makePayment = "makePayment",
  paymentSuccess = "paymentSuccess",
  paymentHold = "paymentHold",
  paymentError = "paymentError",
}

const StakeIn = () => {
  const [paymentState, setPaymentState] = useState<PaymentFlow>(
    PaymentFlow.makePayment
  );

  const stakeIn = useSelector((state: RootState) => state.app.stakeIn);

  if (paymentState === PaymentFlow.makePayment)
    return (

        <StripePricingTable />
//      <CreatePayment setPaymentState={setPaymentState} stakeIn={stakeIn} />
    );

  if (paymentState === PaymentFlow.paymentSuccess) return <PaymentSuccess />;

  if (paymentState === PaymentFlow.paymentHold) return <PaymentHold />;

  if (paymentState === PaymentFlow.paymentError) return <PaymentError />;

  return null;
};

export default StakeIn;
