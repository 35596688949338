const PoolStake: React.FC<{
  poolContract: any;
  invested: string;
  poolname: string;
  poolColor: string;
  poolAddress: string;
  poolSummary: string[];
  stakeIn: (poolAddress: string, valueToStake: string) => Promise<void>;
}> = () => {
  return <></>;
};

export default PoolStake;
