const EventTable : React.FC<{title:string; events: any}> = ({ title, events })  => {

    return ( <div>
        <h3>{title}</h3>
            <table>
                <thead>
                    <tr>
                        <th>Contract Address</th>
                        <th>Previous Owner</th>
                        <th>New Owner</th>
                        <th>Hash</th>
                        <th>Number</th>
                        <th>Created At</th>
                    </tr>
                </thead>
            <tbody>
            {events.map((event : any) => (
                <tr key={`${event.hash}-${event.number}`}>
                <td>{event.contractAddress}</td>
                <td>{event.previousOwner}</td>
                <td>{event.newOwner}</td>
                <td>{event.hash}</td>
                <td>{event.number}</td>
                <td>{new Date(event.createdAt).toLocaleString()}</td>
                </tr>
            ))}
            </tbody>
        </table>
        </div>)
    }
    
export default EventTable;
  