import axios from 'axios';
import { getValidToken } from './tokenUtils';

const api = axios.create({
  baseURL: '/api',
});

api.interceptors.request.use(async (config) => {
  const token = await getValidToken();
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

api.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  if (error.response.status === 401) {
    // Handle unauthorized access (e.g., redirect to login)
    window.location.href = '/login';
  }
  return Promise.reject(error);
});

export default api;