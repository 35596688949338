
import React, { useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';

const StripePricingTable: React.FC = () => {
useEffect(() => {
    const loadAndInitializeStripe = async () => {
    const stripe = await loadStripe('pk_test_51P4fUCL2vpc5cVKisePBEmQrk0GtJm7g1WzaXKWTpvQnOy5A94MtKcooWSs3CIjeuoxQEnyzb5fKbaI3VujB4stc00QwQOaNF1');
    if (!stripe) {
        console.error('Stripe failed to load');
        return;
    }

    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
        const pricing = document.createElement('stripe-pricing-table');
        pricing.setAttribute('pricing-table-id', 'prctbl_1PdwtIL2vpc5cVKiCfkCGpE7');
        pricing.setAttribute('publishable-key', 'pk_test_51P4fUCL2vpc5cVKisePBEmQrk0GtJm7g1WzaXKWTpvQnOy5A94MtKcooWSs3CIjeuoxQEnyzb5fKbaI3VujB4stc00QwQOaNF1');
        pricing.setAttribute('c56ef073-dfc0-4ad2-b1fc-c94db710f1e5', 'client_reference_id');
        
        // Optional: Set customer email
        // pricing.setAttribute('customer-email', 'customer@example.com');

        const container = document.getElementById('stripe-pricing-table-container');
        if (container) {
        container.appendChild(pricing);
        }
    };
    };

    loadAndInitializeStripe();

    // Cleanup function
    return () => {
    const script = document.querySelector('script[src="https://js.stripe.com/v3/pricing-table.js"]');
    if (script) {
        document.body.removeChild(script);
    }
    };
}, []);

return (<div id="stripe-pricing-table-container"></div>)
};

export default StripePricingTable;