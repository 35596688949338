import React, { Component } from "react";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="w-wull">
          <div className="row">
            <div className="max-w-5xl mx-auto">
              <a className="ToTop" href="javascript:;">
                <img
                  className="LogoFooter"
                  src="https://cardefier.io/img/car-defier-footer.png"
                  alt="Card Defier"
                />
              </a>
            </div>

            <div className="col-xs-12">
              <p className="Copy">© 2024. Car Defier</p>
            </div>
          </div>
        </div>
      </footer>

      <section id="Legal" className="SectRow">
        <div>
          <div className="row">
            <div className="max-w-5xl mx-auto">
              <div className="TxtLegal">
                <p>
                  This site is operated by Car Defier LLC, which is not a
                  registered broker-dealer or investment advisor. Car Defier LLC
                  does not give investment advice, endorsement, analysis or
                  recommendations with respect to any securities. Nothing on
                  this website should be construed as an offer to sell,
                  solicitation of an offer to buy or a recommendation for any
                  security by Car Defier LLC or any third party. This
                  information is not an offer to invest in any token, fund or
                  other opportunity and is provided for information only.
                  Nothing in this website shall be deemed to constitute an
                  offer, offer to sell, or the solicitation of an offer to buy,
                  any securities in any U.S. jurisdiction. Residents of the
                  following countries are not permitted to invest in Car Defier:
                  United States of America, Afghanistan, Belarus, Bosnia and
                  Herzegovina, Central African Republic, China, Congo, Guinea,
                  Iran, Iraq, Libya, North Korea, Somalia, Yemen, Zimbabwe.
                </p>

                <p>
                  You are solely responsible for determining whether any
                  investment, investment strategy, security or related
                  transaction is appropriate for you based on your personal
                  investment objectives, financial circumstances and risk
                  tolerance. You should consult with licensed legal
                  professionals and investment advisors for any legal, tax,
                  insurance or investment advice. All securities listed here are
                  being offered by, and all information included on this site is
                  the responsibility of, the applicable issuer of such
                  securities. Car Defier LLC does not guarantee any investment
                  performance, outcome or return of capital for any investment
                  opportunity posted on this site. By accessing this site and
                  any pages thereof, you agree to be bound by the Terms of
                  Service and Privacy Policy. Please view our whitepaper as
                  well.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
