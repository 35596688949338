import axios from "axios";
import { CarDetail, CarLoanContractFull, CarLoanEvent, CarLoanCapitalize, CarLoan, CarLoanCollection } from "./carTypes";

export const getCarLoanEvent = async (carLoanAddress: string) => {
  const url =  process.env.REACT_APP_API_SERVER +
    "/api/collection/event/" +
    carLoanAddress;
};

export const getCarLoansList = async (poolAddress: string) : Promise<CarDetail[]> => {
  const response = await axios.get(`/api/carloan-contract/${poolAddress}/pool-list`)
  return response.data
};

export const getCarLoansAvailable = async () => {
  const response = await axios.get<CarLoan[]>("/api/carloan/available/")
  return response.data
};

export const getCarLoanContracts  = async () => {
  const response = await axios.get("/api/carloan-contract/")
  return response.data
};

export const getCarLoanContract = async (carLoanAddress: string) : Promise<{carLoanContract: CarLoanContractFull}> => {
  const url ="/api/carloan-contract/" + carLoanAddress + "/state/"; 
  const response = await axios.get<{carLoanContract: CarLoanContractFull}>(url)
  return response.data
};

export const getCarLoanContractCollection = async (carLoanAddress: string) : Promise<CarLoanCollection[]> => {
  const url ="/api/carloan-contract/" + carLoanAddress + "/collection"; 
  const response = await axios.get<CarLoanCollection[]>(url)
  return response.data
};

export const getCarLoanContractCapitalize = async (carLoanAddress: string) : Promise<CarLoanCapitalize[]> => {
  const url ="/api/carloan-contract/" + carLoanAddress + "/capitalize"; 
  const response = await axios.get<CarLoanCapitalize[]>(url)
  return response.data
};
