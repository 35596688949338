import { useSelector } from "react-redux";
import { StoreState } from "../../services/app/store";
import { useDispatch } from "react-redux";
import { updateApp } from "../../services/app/appSlice";
import StakeIn from "../stake/payment/StakeIn";
import StripePricingTable from "../stripecheckout/PricingTable";

const Modal = () => {
  const app = useSelector((state: StoreState) => state.app);
  const dispatch = useDispatch();

  const handleBackgroundClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // Check if the click is on the modal overlay (background) and not on the modal content
    if (event.target === event.currentTarget) {
      close();
    }
  };

  const close = () => {
    dispatch(updateApp({ stakeIn: undefined }));
  };

  if (app.stakeIn)
    return (
      <div
        onClick={handleBackgroundClick}
        className="fixed z-20 inset-0 bg-black w-full bg-opacity-30 backdrop-filter backdrop-blur-md flex justify-center items-center flex items-center h-screen"
      >
        <div className="flex mmd:w-[800px] h-[500px] ">
          <div className="bg-white rounded-xl p-6 m-6">
            <div className="flex">
              <button onClick={() => close()} className="ml-auto">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="overflow-y-auto h-3/4">
            {/* <StakeIn /> */}
            <StripePricingTable />
            </div>
          </div>
        </div>
      </div>
    );

  return null;
};

export default Modal;
